:root {
  --sbc-color-background: #2E2E2E;
  --sbc-color-background-secondary: #393939;
  --sbc-font-family-logo-name: 'Passero One', cursive;
  --sbc-font-family-header: 'Josefin Sans', sans-serif;
  --sbc-font-family-normal: 'Ubuntu', sans-serif;
  --sbc-color-text-normal: #FFFFFF;
  --sbc-color-text-accent: #EC9709;
  --sbc-mobile-width: 1024px;
}

html {
  background-color: var(--sbc-color-background);
  font-size: 12pt;
  margin: 0px;
  padding: 0px;
}

.sbc {
  width: 100%;
  height: 100%;
  font-family: var(--sbc-font-family-normal);
  -webkit-font-smoothing: antialiased;
}

.sbc-section-default {
  display: flex;
  width: 80%;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 1.4rem;
  padding-bottom: 4rem;
}

.sbc-section-header {
  display: flex;
  align-self: center;
  width: 100%;
  padding-top: 0.65rem;
  padding-bottom: 1.65rem;
  text-align: left;
  font-family: var(--sbc-font-family-header);
  font-weight: 700;
  font-size: 2.4rem;
  color: var(--sbc-color-text-accent);
}

.sbc-nav {
  display: flex;
  flex-direction: row;
  width: 90%;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.sbc-nav-identity {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-grow: 1;
}

.sbc-nav-identity-logo {
  display: flex;
  height: 60px;
}

@media only screen and (min-width: 1024px) {
  .sbc-nav-identity-logo {
    height: 67px;
  }
}

.sbc-nav-identity-name {
  display: flex;
  align-self: center;
  flex-grow: 1;
  margin-left: 1rem;
  font-family: var(--sbc-font-family-logo-name);
  font-size: 2.5rem;
  letter-spacing: 0.1rem;
  color: var(--sbc-color-text-accent);
}

.sbc-nav-links {
  display: none;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 2;
  gap: 0rem 1.2rem;
  font-family: var(--sbc-font-family-header);
  font-weight: 600;
  font-size: 1.6rem;
}

@media only screen and (min-width: 1024px) {
  .sbc-nav-links {
    display: flex;
  }
}

.sbc-nav-links > div > a {
  text-decoration: none;
  color: inherit;
}

.sbc-nav-social {
  display: none;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
  gap: 0.3rem;
}

@media only screen and (min-width: 1024px) {
  .sbc-nav-social {
    display: flex;
  }
}

.sbc-nav-social-icon {
  height: 35px;
  padding: 0.3rem;
}

.sbc-nav-menu-icon {
  display: flex;
  align-self: center;
  height: 35px;
  cursor: pointer;
}

@media only screen and (min-width: 1024px) {
  .sbc-nav-menu-icon {
    display: none;
  }
}

.sbc-menu {
  display: none;
  flex-direction: column;
  width: 100%;
  gap: 2.5rem 0px;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.sbc-menu-item {
  font-family: var(--sbc-font-family-header);
  font-weight: 600;
  font-size: 1.25rem;
}

.sbc-menu-item > a {
  text-decoration: none;
  color: inherit;
}

.sbc-menu-item-social-icon {
  height: 35px;
  padding: 0.3rem;
}

.sbc-splash {
  display: flex;
  width: 100%;
}

.sbc-splash-image {
  width: 100%;
}

.sbc-mint {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  background-color: var(--sbc-color-background-secondary);
}

.sbc-mint-connect {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem 0rem;
    padding-bottom: 1rem;
}

.sbc-mint-connect-status {
    font-size: 1.6rem;
}

.sbc-mint-connect-button  {
  width: 200px;
  height: 50px;
  font-size: 1.25rem;
  font-weight: 700;
  border-radius: 12px;
  border: none;
  background: #9B9B9B;
  cursor: pointer;
}

.sbc-mint-connect-button:hover {
  background: #5E5F5F;
}

.sbc-mint-connected {
  display: none;
  flex-direction: column;
  align-self: center;
  align-items: center;
  width: 100%;
  gap: 1.5rem 0rem;
  padding-bottom: 1.5rem;
}

@media only screen and (min-width: 1024px) {
  .sbc-mint-connected {
    width: 70%;
  }
}

.sbc-mint-connected-header {
  font-size: 1.4rem;
}

.sbc-mint-connected-address {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
  font-size: 1rem;
  word-wrap:break-word;
}

.sbc-mint-connected-presale {
  display: none;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
  font-size: 1rem;
}

.sbc-mint-connected-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
}

.sbc-mint-connected-controls > form > input {
    width: 50%;
}

.sbc-mint-connected-price {
  font-size: 1rem;
}

.sbc-mint-connected-mint-button {
  margin-top: 1rem;
  width: 200px;
  height: 50px;
  border-radius: 12px;
  border: none;
  font-size: 1.1rem;
  font-weight: 700;
  background: #9B9B9B;
  cursor: pointer;
}

.sbc-mint-connected-mint-button:hover {
  background: #5E5F5F;
}

.sbc-mint-connected-status {
  display: none;
  margin-top: 1.5rem;
  font-size: 1.25rem;
  text-align: center;
  color: white;
}

.sbc-mint-connected-status > span > a {
  color: inherit;
}

.sbc-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  gap: 20px 0px;
  padding-top: 3rem;
}

.sbc-description-paragraph {
  display: flex;
  flex-direction: column;
  width: 100%;
  line-height: 1.9rem;
  font-size: 1.4rem;
}

@media only screen and (min-width: 1024px) {
  .sbc-description-paragraph {
    width: 70%;
  }
}

.sbc-description-paragraph > span {
  padding: 3px;
}

.sbc-gallery {
  display: flex;
  flex-direction: column;
  background-color: var(--sbc-color-background-secondary);
}

.sbc-gallery-examples {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-self: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 20px;
  gap: 30px 30px;
}

.sbc-gallery-examples-image {
  display: flex;
  width: 300px;
  height: 300px;
}

.sbc-roadmap {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  gap: 40px;
}

.sbc-roadmap-milestone {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.6rem 0rem;
}

.sbc-roadmap-milestone-name {
  display: flex;
  font-family: var(--sbc-font-family-header);
  font-size: 2rem;
}

.sbc-roadmap-milestone-description {
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  line-height: 1.5rem;
  gap: 0.8rem 0rem;
}

.sbc-team {
  display: flex;
  flex-direction: column;
  background-color: var(--sbc-color-background-secondary);
}

.sbc-team-members {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-self: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 50px 150px;
}

.sbc-team-members-member {
  display: flex;
  flex-direction: column;
  justify-content: top;
  flex-wrap: wrap;
  width: 300px;
}

.sbc-team-members-member-image {
  display: flex;
  width: 300px;
  height: 300px;
  padding-bottom: 5px;
}

.sbc-team-members-member-name {
  display: flex;
  margin-top: 5px;
  font-size: 1.2rem;
  font-weight: 600;
}

.sbc-team-members-member-description {
  display: flex;
  margin-top: 5px;
  text-align: left;
  font-size: 1.0rem;
}

.sbc-team-members-member-social {
  display: flex;
  margin-top: 10px;
  width: 25px;
  height: 25px;
}

.sbc-faq {
  display: flex;
  flex-direction: column;
}

.sbc-faq-accordion {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 100%;
}

.sbc-faq-accordion-item {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sbc-faq-accordion-item-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  font-size: 1.1rem;
  font-weight: 700;
  cursor: pointer;
}

.sbc-faq-accordion-item-header-icon {
    display: flex;
    margin-right: 15px;
    width: 15px;
    height: 15px;
}

.sbc-faq-accordion-item-content {
  display: none;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 23px;
  text-align: left;
  font-size: 1rem;
}

.sbc-footer {
  display: flex;
  justify-content: center;
  width: 80%;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  background-color: var(--sbc-color-background-secondary);
}

.sbc-footer > span > a {
  color: inherit;
}
