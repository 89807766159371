:root {
  --rdl-font-family: 'Dosis', sans-serif;
  --rdl-color-background-projects: #222424;
  --rdl-color-background-main: #1C1D1D;
  --rdl-color-text-light: #FFFFFF;
  --rdl-color-text-dark: #ADADAD;
}

html, body, #root, #root>div {
  height: 100%;
  width: 100%;
  margin: 0px;
}

html {
  color: var(--rdl-color-text-light);
  font-family: var(--rdl-font-family);
  text-align: center;
  font-size: 12pt;
}

.rdl {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.rdl-projects {
  display: none;
  flex-direction: column;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  background-color: var(--rdl-color-background-projects);
}

@media only screen and (min-width: 1024px) {
  .rdl-projects {
    display: flex;
  }
}

.rdl-projects-header {
  display: flex;
  justify-content: center;
  margin-top: 9rem;
  margin-bottom: 0.5rem;
  font-size: 1.4em;
  color: var(--rdl-color-text-light);
}

.rdl-projects-list {
  display: flex;
  flex-direction: column;
  grid-gap: 0.25rem 0rem;
  color: var(--rdl-color-text-dark);
}

.rdl-projects-item {
  display: flex;
  font-size: 1.1rem;
}

.rdl-projects-item > span > a {
  white-space: pre;
  text-decoration: none;
  color: inherit;
}

.rdl-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: var(--rdl-color-background-main);
}

.rdl-main-social {
  display: flex;
  align-self: flex-end;
  margin-top: 1rem;
  margin-right: 1rem;
}

.rdl-main-social-icon {
  width: 1.4rem;
  height: 1.4rem;
  padding: 0.625rem;
}

.rdl-main-logo {
  display: flex;
  width: 20%;
  height: auto;
  margin-top: 3rem;
}

.rdl-main-name {
  display: flex;
  margin-top: 1.8rem;
  font-size: 2.6rem;
  font-weight: bold;
}

.rdl-main-description {
  display: flex;
  margin-top: 4rem;
  font-size: 1.4rem;
  color: var(--rdl-color-text-dark);
}

.rdl-main-email {
  display: flex;
  flex-grow: 1;
  margin-top: 0.625rem;
  font-size: 1.2rem;
}

.rdl-main-email > span > a {
    color: var(--rdl-color-text-dark);
}

.rdl-main-projects {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-start;
}

@media only screen and (min-width: 1024px) {
  .rdl-main-projects {
    display: none;
  }
}

.rdl-main-projects-header {
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
  font-size: 1.8em;
  color: var(--rdl-color-text-light);
}

.rdl-main-projects-list {
  display: flex;
  flex-direction: column;
  grid-gap: 0.25rem 0rem;
  color: var(--rdl-color-text-dark);
}

.rdl-main-projects-item {
  display: flex;
  font-size: 1.4rem;
}

.rdl-main-projects-item > span > a {
  white-space: pre;
  text-decoration: none;
  color: inherit;
}

.rdl-main-footer {
  display: flex;
  align-items: flex-end;
  margin-bottom: 1rem;
  color: var(--rdl-color-text-dark);
}
