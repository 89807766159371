:root {
  --sbc-admin-color-background-controls: #222424;
  --sbc-admin-color-background-display: #1C1D1D;
  --sbc-admin-color-text-light: #FFFFFF;
  --sbc-admin-color-text-pending: #DCDC00;
  --sbc-admin-color-text-success: #85DC00;
  --sbc-admin-color-text-error: #FF5500;
}

html, body, #root, #root>div {
  height: 100%;
  width: 100%;
  margin: 0px;
  font-size: 12pt;
}

.sbc-admin {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.sbc-admin-controls {
  display: none;
  flex-direction: column;
  padding-top: 3rem;
  background-color: var(--sbc-admin-color-background-controls);
}

.sbc-admin-controls-access-restricted {
  margin-top: 50px;
  font-size: 1.5em;
  color: var(--sbc-admin-color-text-light)
}

.sbc-admin-controls-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 1.5rem;
  padding-bottom: 3rem;
  text-align: left;
}

.sbc-admin-controls-group-header {
  font-size: 1.5em;
}

.sbc-admin-controls-group-contents {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 2rem;
  padding-right: 2rem;
}

.sbc-admin-controls-group-contents > button {
  margin-top: 16px;
  font-size: 1em;
}

.sbc-admin-controls-group-contents-form {
  margin-top: 20px;
  margin-bottom: 10px;
}

.sbc-admin-controls-group-contents-form > form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
  font-size: 1em;
}

.sbc-admin-controls-group-contents-form > button {
  font-size: 1em;
}

.sbc-admin-controls-transaction {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 3rem;
  text-align: left;
  font-size: 1.1em;
}

.sbc-admin-controls-transaction-pending {
  color: var(--sbc-admin-color-text-pending);
}

.sbc-admin-controls-transaction-success {
  color: var(--sbc-admin-color-text-success);
}

.sbc-admin-controls-transaction-error {
  color: var(--sbc-admin-color-text-error);
}

.sbc-admin-controls-transaction-link {
  display: block;
  margin-top: 10px;
  color: var(--sbc-admin-color-text-light);
}

.sbc-admin-controls-transaction-clear {
  display: block;
  margin-top: 20px;
}

.sbc-admin-display {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  padding: 3rem;
  width: 100%;
  background-color: var(--sbc-admin-color-background-display);
}

.sbc-admin-display-header {
  display: flex;
  font-size: 1.5em;
}

.sbc-admin-display-item {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 10px;
  font-size: 1.1em;
}

.sbc-admin-display-item-key {
  display: flex;
  width: 20%;
  text-align: right;
}

.sbc-admin-display-item-value {
  display: flex;
  text-align: left;
  padding-left: 20px;
}
